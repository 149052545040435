import * as React from 'react'
import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import './styles.scss'

const Section = loadable(() =>
    import('/src/components/Structure/Section/Section')
)

const SearchResultsContainer = ({
    containerVariant,
    size,
    title,
    children
}) => {
    const content = (
        <>
            <h2 className="c-search-results__title">{parse(title)}</h2>

            {children}
        </>
    )

    return (
        <Section
            id={'searchTop'}
            variant={containerVariant}
            className={'c-search-results'}
        >
            {size === 'narrow' ? (
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">{content}</div>
                </div>
            ) : (
                content
            )}
        </Section>
    )
}

SearchResultsContainer.propTypes = {
    containerVariant: PropTypes.oneOf([
        'light',
        'dark',
        'alt',
        'gradient',
        'other'
    ]),
    size: PropTypes.oneOf(['full', 'narrow']).isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

SearchResultsContainer.defaultProps = {
    size: 'full',
    title: 'Search Results Title',
    children: <p>Search results go here</p>
}

export default SearchResultsContainer
